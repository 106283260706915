export const contactHeader = {
  small: false,
  displayBnt: false,
  title: "Get in touch",
  h1: true,
  subtitle: `We're here to help`,
  label: '',
  link: '',
  url: '',
  bgColor: "#a8cf37",
  padding: true,
}

export const signUpBanner = {
  small: false,
  displayBnt: false,
  title: "Sign Up",
  h1: true,
  subtitle: `It'll only take a moment of your time - and you'll never have to worry about paying for motor legal insurance again.`,
  label: '',
  link: '',
  url: '',
  bgColor: "#0bbcbf",
  padding: true,
}

export const reportClaimsBanner = {
  small: false,
  displayBnt: false,
  title: "Report your claim",
  h1: true,
  subtitle: `Fill out our online claim form`,
  label: '',
  link: '',
  url: '',
  bgColor: "#0bbcbf",
  padding: true,
}

export const privacyHeader = {
  small: false,
  displayBnt: false,
  title: "Privacy Policy",
  h1: true,
  subtitle: ``,
  label: '',
  link: '',
  url: '',
  bgColor: "#a8cf37",
  padding: true,
}

