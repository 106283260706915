import React from "react";
import { policies } from "../utils/how-it-works/privacyPolicy.js";
import ListItem from "../components/list-item/list-item.js";
import "../styles/pages/privacy-policy.scss";
import Layout from "../layouts/index.js";
import Banner from "../components/banner/banner.js";
import { privacyHeader } from "../utils/contactInformation"

const PrivacyPolicy = () => {

    return (
        <Layout SEOTitle={"Privacy Policy | Free Motor Legal"} SEODescription={""} footerDisclaimer={""}>

            <Banner props={privacyHeader} />

            <div className="privacy-policy-container">
                {
                    policies.length ? policies.map((item, index) => {

                        return (
                            <div key={index}>
                                {
                                    item.title ? <span className="colored-title">{item.title}</span> : ""

                                }
                                {
                                    item.description ? Array.isArray(item.description) ? item.description.map((itemText, index) => {

                                        return (
                                            <p style={{ margin: "5px" }} key={index}>{itemText.text}</p>
                                        )
                                    }) : <p>{item.description}</p> : ""
                                }
                                {
                                    item.itemList ?
                                        <div style={{ paddingBottom: "20px" }}>
                                            {
                                                item.itemList?.length ? item.itemList.map((listItem, index) => {

                                                    return (
                                                        <>
                                                            <ListItem text={listItem.title} key={index} />
                                                            <span>{listItem.description}</span>
                                                        </>
                                                    )
                                                }) : ""
                                            }
                                        </div>
                                        : ""
                                }

                            </div>
                        )
                    }) : ""
                }
            </div>
        </Layout>
    )
}

export default PrivacyPolicy;